export const APP_TITLE = 'Jet nix';
export const APP_ASSETS_URL = 'https://assets.jet-nix.com';
export const APP_PAGE_DESCRIPTION_EN = 'Join the Jet nix Casino from PC and mobile and discover bonuses without condition to win on hundreds of games';
export const APP_PAGE_DESCRIPTION_FR = 'Rejoignez le programme VIP Casino depuis PC et mobile et découvrez les bonus sans conditions pour gagner sur des centaines de jeux et retirer en 24h';
export const BACK_TO_HOME_EN = 'Back to homepage';
export const BACK_TO_HOME_FR = 'Retour à la page d\'accueil';
export const GOLD = 'gold';
export const SILVER = 'silver';
export const BRONZE = 'bronze';
export const COIN_MULTIPLIER = 15;
